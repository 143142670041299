<template>
  <div class="blog-detail">
    <Main-top
      :title="name"
      :contentDate="created_at"
      class="blog-main-top"
    ></Main-top>
    <v-container class="mb-10 inner-container inner-container--content">
      <article class="blog-detail__article mb-5">
        <v-img
          v-if="isPhotoShow"
          class="mb-10 ma-auto mt-10"
          :src="photoUrl"
          :alt="photoName"
          :aspect-ratio="16 / 9"
          :max-width="ImgDetailMax"
        ></v-img>
        <div v-html="content" class="html-editor mb-5"></div>
      </article>
      <div
        class="
          d-flex
          align-start
          flex-sm-row flex-column
          justify-space-between
          mb-10
        "
      >
        <div class="blog-categories">
          <!-- 類別按鈕 -->
          <Main-btn
            v-for="category in categories"
            :key="category.id"
            :color="categoryBtnColor"
            class="white--text mr-2 my-1"
            @click="goIndex(category)"
          >
            <span>{{ category.category.name }}</span>
          </Main-btn>
        </div>
        <div class="blog-social-icons d-flex my-sm-0 my-6">
          <Share />
        </div>
      </div>
      <section v-if="!filesEmpty">
        <p class="dynamic-sub-title mb-10">{{ $t("file.download") }}</p>
        <FileBox :files="files" />
      </section>
    </v-container>
    <!-- recommend -->
    <section class="recommend-block mt-15">
      <component :is="topWaveComponent" class="top-wave"></component>
      <v-container class="inner-container">
        <p class="dynamic-sub-title mb-10">{{ $t("article.recommend") }}</p>
        <p v-if="!showRecommend" class="text-2 text-center">
          {{ $t("data.empty") }}
        </p>
        <v-row v-else>
          <v-col
            v-for="item in computedRecommendList"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
          >
            <SmallItem :item="item" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import detailMixins from "@/components/page/detail.js";
import EcvWaveT from "@/components/wave/ecvWaveT.vue";
import qs from "qs";
export default {
  mixins: [detailMixins],
  components: {
    Share: () => import("@/components/share/share.vue"),
    SmallItem: () => import("@/components/blog/smallItem.vue"),
    FileBox: () => import("@/components/fileBox.vue"),
  },
  data: () => ({}),
  computed: {
    isMedia() {
      return this.$route.name === "media-detail";
    },
    breadcrumb() {
      return [
        {
          title: this.isMedia ? this.$t("page.media") : this.$t("page.blog"),
          name: this.isMedia ? "media-index" : "blog-index",
        },
        {
          title: this.name,
          name: this.isMedia ? "media-detail" : "blog-detail",
        },
      ];
    },
    api() {
      if (this.hasPreviewLink) return this.$api.blog.admin.preview;
      return this.$api.blog.public.read;
    },
    recommendApi() {
      return this.$api.blog.public.recommend;
    },
    topWaveComponent() {
      return EcvWaveT;
    },
    windowWidth() {
      return this.$store.getters['base/windowWidth']
    },
    categoryBtnColor() {
      if(this.windowWidth >= this.$vuetify.breakpoint.thresholds.sm) return 'secondary'
      return 'primary'
    },
  },
  methods: {
    goIndex(category) {
      this.$router.push({
        name: this.isMedia ? "media-index" : "blog-index",
        query: {
          q: qs.stringify({
            category: [category.category.id],
          }),
        },
      });
    },
  },
};
</script>
